'use client';

import { Link } from 'next-view-transitions';
import Image from 'next/image';

import { ChevronRight } from '@ui/components/icons/icons';
import { Section } from '@ui/components/layout/section/section';
import { Button } from '@ui/components/ui/button';
import { getSizes } from '@ui/lib/images';
import { cn } from '@ui/lib/utils';

export function Hero({ className }: { className?: string }) {
	return (
		<Section className={cn(className, 'grid grid-cols-4 justify-center gap-8')}>
			<div className='order-2 col-span-full flex flex-col justify-center gap-8 sm:order-1 sm:col-span-2 sm:gap-12 lg:gap-16 xl:col-span-3'>
				<div className='flex flex-col gap-2'>
					<h1 className='text-5xl font-bold'>
						Hi, I'm <span className='text-primary'>Sander Korf</span>.
					</h1>
					<p className='text-2xl md:max-w-3xl'>
						I'm a Freelance AI Engineer & Full Stack Developer with 12+ years of experience in tech and development.
						Specializing in Generative AI and front-end solutions, dedicated to transforming businesses through
						automation and efficiency.
					</p>
				</div>

				<div className='border-l-secondary flex flex-col gap-2 border-l-2 py-2 pl-4'>
					<Link
						title='Send mail to Sander'
						href='mailto:sander@sdkode.dev?subject=Free consultation'
						className='link-hover w-fit'>
						sander@sdkode.dev
					</Link>
					<span>Amsterdam Area, The Netherlands</span>
				</div>

				<div className='max-sm:flex sm:hidden'>
					<Image
						className='mask mask-squircle w-full'
						src='/images/sander-korf.png'
						alt='Picture of Sander Korf'
						sizes={getSizes()}
						width={260}
						height={300}
					/>
				</div>

				<div className='flex flex-col gap-4 sm:flex-row'>
					<Button variant='outline' className='w-full sm:w-fit md:mt-auto' asChild>
						<Link className='max-sm:flex-1' href='/portfolio' scroll={false} title='Navigate to my portfolio'>
							See my portfolio <ChevronRight />
						</Link>
					</Button>

					<Button variant='outline' className='w-full sm:w-fit md:mt-auto' asChild>
						<Link className='max-sm:flex-1' href='/resume' scroll={false} title='Navigate to my resume'>
							Read my resume <ChevronRight />
						</Link>
					</Button>

					<Button className='w-full sm:w-fit md:mt-auto' asChild>
						<Link className='max-sm:flex-1' href='/schedule' title='Schedule a call' target='_blank'>
							Schedule call <ChevronRight />
						</Link>
					</Button>
				</div>
			</div>
			<div className='order-1 col-span-full hidden sm:order-2 sm:col-span-2 sm:flex xl:col-span-1'>
				<Image
					className='mask mask-squircle w-full object-contain'
					src='/images/sander-korf.png'
					alt='Picture of Sander Korf'
					sizes={getSizes()}
					width={260}
					height={300}
				/>
			</div>
		</Section>
	);
}
