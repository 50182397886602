import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import { cn } from '@ui/lib/utils';

type Props = PropsWithChildren<HTMLAttributes<HTMLElement>> & {
	title?: string | ReactNode;
	text?: string | ReactNode;
};

export function Section({ title, text, className, children, ...props }: Props) {
	const hasTitleOrText = title || text;

	return (
		<section className={cn('flex')} {...props}>
			<div className={cn(className, 'bg-background container py-8 md:pt-12 lg:pt-16')}>
				{hasTitleOrText && (
					<div className='flex flex-col gap-2'>
						{title && <h2 className='text-4xl font-semibold'>{title}</h2>}
						{text && <p>{text}</p>}
					</div>
				)}

				{children}
			</div>
		</section>
	);
}
