type ScreenSizes = {
	sm: string;
	md: string;
	lg: string;
	xl: string;
	'2xl': string;
};

export const screenSizes: ScreenSizes = {
	sm: '600px',
	md: '1024px',
	lg: '1280px',
	xl: '1440px',
	'2xl': '1920px',
};

export const getSizes = (): string => {
	const imageSizes: Record<string, string> = {
		'2xl': '1920px', // slightly larger for high-density large screens
		xl: '1400px', // caters to typical desktops and high-density tablets
		lg: '1200px', // ideal for tablets and small desktops
		md: '900px', // targets larger phones and small tablets
		sm: '600px', // for mobile devices, considering high-density screens
	};

	if (Object.values(screenSizes).every((size) => size === '')) {
		return '100vw';
	}

	return (
		Object.entries(screenSizes)
			.reverse()
			.reduce((acc, [key, value]) => {
				return acc + `(min-width: ${value}) ${imageSizes[key]}, `;
			}, '') + '100vw'
	);
};
